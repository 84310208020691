import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../util.service';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  university_id: number;
  gridColumns: any[] = [];
  gridData: any[] = [];
  selectedRows: any[] = [];
  groups: any[] = [];
  careers: any[] = [];
  showSpinner: boolean = false;
  form: FormGroup;
  statusView: string = 'grid';
  tituloDeLaVentana: string = 'Hoja de Revisión';
  subtituloDeLaVentana: string = 'Todas las empresas';
  showFilterPopup: boolean = false;
  filters: any = {};  
  listaDeBotones: any[] = [
    { texto: 'Filtrar ▼', onClick: () => this.filterData() },
  ];
  constructor(
    private dataService: DataService,
    private utilService: UtilService, 
  ) { }

  ngOnInit(): void {
    this.university_id = this.getUniversityId();
    this.loadTablaData(null);
    this.loadGroups();
    this.loadCareers();
  }

  loadTablaData(search: any): void {
    this.showSpinner = true;
    let params = {class_id: null, multiple: false, student_id: null, empresa_base:null, teacher_id:null, student:null};
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    let params_empty = {};
    let params_to_use: any;
    let noClass = false
    // Students filter by class_id
    //params.class_id  = user.is_admin ? null : user.teacher_id;
    console.log("User", search)
    if (!user.is_admin) {
      if (search != null) {
        params.class_id = search.group_id || null;
        params.student_id = search.student || null;
        params.empresa_base = search.empresa_base || null;
        params.teacher_id = user.teacher_id || null;
        params.student = search.name || null;
        params_to_use = params;
        console.log("Params to use", params_to_use)
        this.setSubtitle(params_to_use);
        this.dataService.getSearchData({"university_id":this.university_id, "tablename":"company_v", "params":params_to_use as any}).subscribe((data) => {
          const jsonString = JSON.stringify(data);
          const jsonObject = JSON.parse(jsonString);
          console.log("Leegasd", jsonObject)
          if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
            jsonObject.body.data = [];
          }
          // Asignar datos para el grid
          let cleanData = this.dataService.processGridData(jsonObject);
          this.gridColumns = this.processGridColumns(cleanData.columns);
          this.gridData = cleanData.data;
          this.showSpinner = false;    
        });

      } else {
        this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{"teacher_id":user.teacher_id} as any}).subscribe((data) => {
          const jsonString = JSON.stringify(data);
          const jsonObject = JSON.parse(jsonString);
          console.log("Clases del profesor", jsonObject)
          if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
            jsonObject.body.data = [];
          }
          if (jsonObject.body.data.length == 0) {
            // no hay clases
            params.class_id = 0 as any;
            params_to_use = params;
          } else if (jsonObject.body.data.length == 1) {
            // solo una clase
            params.class_id = jsonObject.body.data[0].id;
            params_to_use = params;
          } else {
            // todas las clases separadas por ,
            params.class_id = jsonObject.body.data.map((x: any) => x.id).join(",");
            params.multiple = true;
            params_to_use = params;
          }
          this.dataService.getTablaData({"university_id":this.university_id, "tablename":"company_v", "params":params_to_use as any}).subscribe((data) => {
            const jsonString = JSON.stringify(data);
            const jsonObject = JSON.parse(jsonString);
            console.log("Leegasd", jsonObject)
            if (jsonObject.body.data == null || jsonObject.body.data.length == 0) {
              jsonObject.body.data = [];
            }
            // Asignar datos para el grid
            let cleanData = this.dataService.processGridData(jsonObject);
            this.gridColumns = this.processGridColumns(cleanData.columns);
            this.gridData = cleanData.data;
            this.showSpinner = false;    
          });
        });
      }
    } 
  }
  loadGroups(): any {
    let params = {class_id: null, multiple: false};
    let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
    let user = JSONUser.user;
    if (!user.is_admin) {
      this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{"teacher_id":user.teacher_id} as any}).subscribe((data) => {
        const jsonString = JSON.stringify(data);
        const jsonObject = JSON.parse(jsonString);
        this.groups = jsonObject.body.data;
      });
    } else {
        // Cargar todos los grupos de la universidad
        this.dataService.getTablaData({"university_id":this.university_id, "tablename":"class", "params":{} as any}).subscribe((data) => {
          const jsonString = JSON.stringify(data);
          const jsonObject = JSON.parse(jsonString);
          this.groups = jsonObject.body.data;
        });
    }
  }

  loadCareers(): any {
    this.dataService.getTablaData({"university_id":this.university_id, "tablename":"career", "params":{} as any}).subscribe((data) => {
      console.log("Load careers", data)
      const jsonString = JSON.stringify(data);
      const jsonObject = JSON.parse(jsonString);
      this.careers = jsonObject.body.data;
    });

  }
  processGridColumns(columns: Text[]): any[] {
    console.log("Columns", columns);
    return columns.map(texto => ({
      header: this.utilService.translateColumn(texto.toString()),
      field: texto,
      editable: false,
      type: texto.toString() === 'rfc'? 'link': 'text',
      hide: texto.toString() === 'id'? true: texto.toString() === 'empresa_id'? true: texto.toString() === 'teacher_id'? true: false,
      url: "https://app.contalink.com/change_company_with_rfc/"
    }));
  }

  getUniversityId(): number {
    if (localStorage.getItem('user') != null) {
      let JSONUser = JSON.parse(localStorage.getItem('user')??"{}");
      let user = JSONUser.user;
      return user.university_id;
    } else {
      return 0;
    }
  }

filterData(): void {
    this.showFilterPopup = !this.showFilterPopup;
 }

 onSubmit(evento: any) {
  this.loadTablaData(evento);
 }

 setSubtitle(params: any): void {
  console.log("Params", params)
  let subtitle = "Filtro: ";
  if (params.class_id != null) {
    let group = this.groups.find(x => x.id == params.class_id);
    if (group != null) {
      subtitle += group.identifier + " ";
    }
  }
  if (params.student != null) {
    subtitle += params.student + " ";
  }

  if (params.empresa_base != null) {
    subtitle += params.empresa_base + " ";
  }

  this.subtituloDeLaVentana = subtitle;
}
}